import React from 'react';
import classnames from 'classnames';

import UserMenu from './UserMenu';

import { triggerEvent } from '../../utils/global';

import styles from '../../sass/components/common/Hamburger.module.scss';

class Hamburger extends React.PureComponent {

  showSidebar = () => {
    triggerEvent('showSidebarOverlay', [{
      content: <UserMenu />,
      right: true,
    }]);
  }

  render = () => {
    const { className } = this.props;

    return (
      <div
        className={classnames(className, {
          [styles.hamburger]: true, 
          [styles.unread]: this.props.unreadEndorsements > 0
        })}
        onClick={this.showSidebar}
      />
    );
  }
}

export default Hamburger;
import React from 'react';
import { connect } from 'react-redux';
import Image from 'next/image';
import { withRouter } from 'next/router';
import classnames from 'classnames';
import Link from 'next/link';

import ConnectWallet from '../auth/ConnectWallet';

import { triggerEvent } from '../../utils/global';

import styles from '../../sass/components/common/UserMenu.module.scss';

const MENU_ITEMS = [
  {
    id: 1,
    key: 'profile',
    name: 'Your Profile',
    url: '/profile',
    auth: true,
  },
  {
    id: 2,
    key: 'edit-profile',
    name: 'Edit Profile',
    url: '/edit-profile',
    auth: true,
  },
  {
    id: 3,
    key: 'browse-profile',
    name: 'Browse Profiles',
    url: '/browse',
    auth: false,
  },
  {
    id: 4,
    key: 'communities',
    name: 'Communities',
    url: '/communities',
    auth: false,
  },
  {
    id: 5,
    key: 'endorsements',
    name: 'Endorsements',
    url: '/endorsements',
    auth: true,
    postfix: (ctx) => ctx.loadEndorsements(),
  },
  {
    id: 6,
    key: 'endorsements',
    name: 'NFT Collection',
    url: 'https://opensea.io/collection/neonpantheon',
    external: true,
    auth: false,
  },
  {
    id: 7,
    key: 'shrinemaker',
    name: 'Shrinemaker',
    url: 'https://neonpantheon.com/enshrine',
    external: true,
    auth: false,
  },
];

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
    unreadEndorsements: store.data.unreadEndorsements,
    wizardStep: store.data.wizardStep,
    isMobile: store.setup?.isMobile,
  };
};

class UserMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      links: [],
    };
  }

  componentDidMount = () => {
    this.updateLinks();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.user !== this.props.user) {
      this.updateLinks();
    }
  }

  updateLinks = () => {
    const links = MENU_ITEMS.filter(link => link.auth && this.props.user || !link.auth);
    this.setState({ links });
  }

  handleLinkClick = (link) => {
    const url = link === '/profile'
      ? this.getProfileLink()
      : link;
    triggerEvent('hideSidebarOverlay');
    this.props.router.push(url);
  }

  getProfileLink = () => {
    return this.props.wizardStep ? this.props.wizardStep : `${this.props.user?.username}`;
  }

  loadEndorsements = () => {
    return this.props.unreadEndorsements > 0 ? <span>{this.props.unreadEndorsements}</span> : null;
  }

  renderPostfix = (postfix) => {
    if (typeof postfix === 'function') return postfix(this);
    return postfix;
  }

  renderLink = (item) => {
    return (
      <div
        className={classnames(styles.link, {
          [styles.active]: this.props.router.pathname === item.url
        })}
        key={`menu_item${item.id}`}
      >
        {item.external
          ? <a href={item.url} target='_blank' rel='noopener noreferrer'>{item.name}</a>
          : <Link
              href={item.url === '/profile'
                ? {
                    pathname: '[username]',
                    query: {
                      username: this.getProfileLink()
                    },
                  }
                : item.url
              }
            >
              <a onClick={() => triggerEvent('hideSidebarOverlay')}>
                <div>
                  {item.name}{item.postfix && this.renderPostfix(item.postfix)}
                </div>
              </a>
            </Link>
        }
      </div>
    );
  }

  goToHomepage = () => {
    triggerEvent('hideSidebarOverlay');
    this.props.router.push('/');
  }

  closeSidebar = () => {
    triggerEvent('hideSidebarOverlay');
  }
  
  renderMobileHeader = () => {
    return (
      <div className={styles.mobileHeader}>
        <div className={styles.image}>
          <Image
            src='/images/common/logo.svg'
            alt='PNTHN logo'
            width='74'
            height='16'
            onClick={this.goToHomepage}
          />
        </div>
        <div className={styles.close} onClick={this.closeSidebar} />
      </div>
    );
  }

  render = () => {
    const { links } = this.state;

    return (
      <div className={classnames(styles.userMenu, {
        [styles.mobile]: this.props.isMobile
      })}>
        {this.props.isMobile && this.renderMobileHeader()}
        {links.map(this.renderLink)}
        <ConnectWallet accented onDisconnect={this.goToHomepage} />
      </div>
    );
  }
};

export default connect(mapStoreToProps)(withRouter(UserMenu));
